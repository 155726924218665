<template>
  <div>
    <b-modal
      class="modal-backdrop"
      :visible="isShowQr"
      title="QR code"
      cancel-title="Close"
      centered
      ok-title="Accept"
      cancel-variant="outline-secondary"
      hide-footer
      @hide="$emit('update:is-show-qr', false)"
      @change="(val) => $emit('update:is-show-qr', val)"
    >
      <b-col class="text-center ">
        <!-- <h3 style="color: #20409B;">
          Customer code
        </h3>
        <p
          class="fs-16"
          style="color: #20409B;"
        >
          Surname: {{ surName }}, Rescode: {{ resCode }}
        </p> -->
        <p
          class="fs-16"
          style="color: #20409B;"
        >
          Use this code to check in at the reception
        </p>
        <div
          ref="printMe"
          class="mb-2"
        >
          <div
            class="text"
          >
            {{ checkHotel() }}
          </div>
          <div
            class="d-flex justify-content-center"
          >
            <div>

              <div class="qr">
                <qrcode-vue
                  class="p-1"
                  style="display: block"
                  :value="codeCustomer"
                  :size="size"
                  level="L"
                />
              </div>
            </div>

          </div>

          <!-- <QRCode
            class="py-2"
            :text="codeCustomer"
            :size="size"
          /> -->
        </div>

        <b-row>
          <b-col>
            <div
              style="cursor: pointer;"
              @click="downloadVisualReport"
            >
              <img
                style="width: 20px"
                src="@/assets/images/icons/downloadImage.png"
                alt=""
              >
              <p
                class="fs-15"
                style="color: #20409B;"
              >
                Save image
              </p>
            </div>
          </b-col>
          <b-col>
            <div
              style="cursor: pointer;"
              @click="printModalBrowser"
            >
              <img
                style="width: 20px"
                src="@/assets/images/icons/printImage.png"
                alt=""
              >
              <p
                class="fs-15"
                style="color: #20409B;"
              >
                Print
              </p>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  // BPagination,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import QrcodeVue from 'qrcode.vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from 'html2canvas'
// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from 'file-saver'
import html2pdf from 'html2pdf.js'
import print from 'print-js'
// import moment from 'moment'
export default {
  components: {
    QrcodeVue,
    BModal,
    BCol,
    BRow,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    isShowQr: {
      type: Boolean,
      required: true,
    },
    codeCustomer: {
      type: String,
      required: true,
    },
    resCode: {
      type: String,
      required: true,
    },
    surName: {
      type: String,
      required: true,
    },
    guestGiven: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      value: 'abc',
      size: 270,
      output: null,
    }
  },
  methods: {
    checkHotel() {
      const hotelKey = localStorage.getItem('hotelKey')
      if (hotelKey === '9') {
        return `${this.guestGiven} ${this.surName}`
      }
      return `${this.surName} - ${this.resCode}`
    },
    showCaptureRef() {
      console.log(this.refs.printMe)
    },
    downloadVisualReport() {
      html2canvas(this.$refs.printMe).then(canvas => {
        canvas.toBlob(blob => {
          saveAs(blob, `${this.codeCustomer}.png`)
        })
      }).catch(_error => {
        console.log(_error)
      })
    },
    exportPdf() {
      // this.loading = true
      setTimeout(async () => {
        const options = {
          margin: [7, 7, 7, 7],
          filename: 'customerFlow.pdf',
          html2canvas: {
            dpi: 1000,
            scale: 6,
            image: { type: 'png', quality: 1 },
            letterRendering: true,
            allowTaint: true,
            logging: true,
            useCORS: true,
          },
          jsPDF: { unit: 'mm', format: 'a7', orientation: 'portrait' },
        }

        await html2pdf()
          .from(this.$refs.printMe)
          .set(options)
          .save()
        // this.loading = false
      }, 10)
    },

    printModalBrowser() {
      // this.loading = true
      setTimeout(async () => {
        await print({
          maxWidth: 1000,
          style: '.qr {margin-bottom: 6rem; margin-left: 6rem; display: flex; justify-content: center; align-items: center} .text {font-weight: bold; text-transform: uppercase; margin-bottom: 2rem; display: flex; text-align: center} ',
          printable: this.$refs.printMe,
          font_size: '30px',
          type: 'html',
        })

        // this.loading = false
      }, 10)
    },
  },

}
</script>
<style lang="scss">
.rotated {
  transform: rotate(-90deg);
  /* Equal to rotateZ(45deg) */
  margin-top: 2rem;
}

.modal-backdrop {
  opacity: 0.5 !important;
}
</style>
