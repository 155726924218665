<template>
  <div>
    <b-modal
      class="modal-backdrop"
      :visible="isShowAddCustomer"
      title="Add customer"
      cancel-title="Close"
      centered
      ok-title="Accept"
      cancel-variant="outline-secondary"
      hide-footer
      @hide="hideModalHandle"
      @change="(val) => $emit('update:is-show-add-customer', val)"
    >
      <b-overlay
        :show="loadingModal"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-col class="text-center ">
          <h3 style="color: #20409B;">
            Input resno
          </h3>
          <validation-observer ref="addCustomerFlowValidation">
            <!-- form -->
            <b-form>
              <b-row class="mt-2">
                <!-- Sur name -->
                <b-col md="8">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Resno"
                      rules="required"
                    >
                      <b-form-input
                        v-model="resCode"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-button
                    block
                    variant="primary"
                    @click="add"
                  >
                    Add
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

          <div v-if="codeCustomer">
            <p
              class="fs-16"
              style="color: #20409B;"
            >
              Use this code to check in at the reception
            </p>
            <div class="mb-2">
              <div
                ref="printMe"
              >
                <div
                  class="text"
                >
                  {{ checkHotel() }}
                </div>
                <div
                  class="d-flex justify-content-center"
                >
                  <div class="qr">

                    <qrcode-vue
                      class="p-1"
                      style="display: block"
                      :value="codeCustomer"
                      :size="size"
                      level="L"
                    />
                  </div>
                </div>

              </div>

              <!-- <QRCode
            class="py-2"
            :text="codeCustomer"
            :size="size"
          /> -->
            </div>

            <b-row>
              <b-col>
                <div
                  style="cursor: pointer;"
                  @click="downloadVisualReport"
                >
                  <img
                    style="width: 20px"
                    src="@/assets/images/icons/downloadImage.png"
                    alt=""
                  >
                  <p
                    class="fs-15"
                    style="color: #20409B;"
                  >
                    Save image
                  </p>
                </div>
              </b-col>
              <b-col>
                <div
                  style="cursor: pointer;"
                  @click="printModalBrowser"
                >
                  <img
                    style="width: 20px"
                    src="@/assets/images/icons/printImage.png"
                    alt=""
                  >
                  <p
                    class="fs-15"
                    style="color: #20409B;"
                  >
                    Print
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-overlay>

    </b-modal>
  </div>
</template>
<script>
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  // BPagination,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from 'html2canvas'
// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from 'file-saver'
import html2pdf from 'html2pdf.js'
import print from 'print-js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QrcodeVue from 'qrcode.vue'

// import moment from 'moment'
export default {
  components: {
    BModal,
    BCol,
    BRow,
    BForm,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    BFormInput,
    BButton,
    BOverlay,
    QrcodeVue,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    isShowAddCustomer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      value: 'abc',
      size: 270,
      output: null,
      resCode: null,
      room: null,
      required,
      loadingModal: false,
      surName: null,
      codeCustomer: null,
      guestGiven: null,
    }
  },
  methods: {
    checkHotel() {
      const hotelKey = localStorage.getItem('hotelKey')
      if (hotelKey === '9') {
        return `${this.guestGiven} ${this.surName}`
      }
      return `${this.surName} - ${this.resCode}`
    },
    hideModalHandle() {
      this.surName = null
      this.codeCustomer = null
      this.resCode = null
      this.$emit('update:is-show-qr', false)
    },
    add() {
      this.$refs.addCustomerFlowValidation.validate().then(success => {
        if (success) {
          this.loadingModal = true
          store.dispatch('customerFlow/addCustomerFlow', this.resCode).then(response => {
            console.log(response)
            this.surName = response.body.surname
            this.room = response.body.room
            this.guestGiven = response.body.guestGiven
            this.$store.dispatch('customerFlow/fetchCustomerFlowQRLink', { id: parseInt(response.body.id) })
              .then(responseQR => {
                this.codeCustomer = responseQR.body.url
                this.loadingModal = false
                this.$emit('refetch-data')
              }).catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.error,
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                })
                this.loadingModal = false
              })
          })
        }
      }).catch(e => {
        console.log(e)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Customer not found',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        this.loadingModal = false
      })
    },
    showCaptureRef() {
      console.log(this.refs.printMe)
    },
    downloadVisualReport() {
      html2canvas(this.$refs.printMe).then(canvas => {
        canvas.toBlob(blob => {
          saveAs(blob, `${this.codeCustomer}.png`)
        })
      }).catch(_error => {
        console.log(_error)
      })
    },
    exportPdf() {
      // this.loading = true
      setTimeout(async () => {
        const options = {
          margin: [7, 7, 7, 7],
          filename: 'customerFlow.pdf',
          html2canvas: {
            dpi: 1000,
            scale: 6,
            image: { type: 'png', quality: 1 },
            letterRendering: true,
            allowTaint: true,
            logging: true,
            useCORS: true,
          },
          jsPDF: { unit: 'mm', format: 'a7', orientation: 'portrait' },
        }

        await html2pdf()
          .from(this.$refs.printMe)
          .set(options)
          .save()
        // this.loading = false
      }, 10)
    },

    printModalBrowser() {
      // this.loading = true
      setTimeout(async () => {
        const options = {
          margin: [7, 7, 7, 7],
          filename: 'customerFlow.pdf',
          html2canvas: {
            dpi: 1000,
            scale: 6,
            image: { type: 'png', quality: 1 },
            letterRendering: true,
            allowTaint: true,
            logging: true,
            useCORS: true,
          },
          jsPDF: { unit: 'mm', format: 'a7', orientation: 'portrait' },
        }

        await print({
          maxWidth: 1000,
          style: '.qr {margin-bottom: 6rem; margin-left: 6rem; display: flex; justify-content: center; align-items: center} .text {font-weight: bold; text-transform: uppercase; margin-bottom: 2rem; display: flex; text-align: center} ',
          printable: this.$refs.printMe,
          font_size: '35px',
          type: 'html',
        })

        // this.loading = false
      }, 10)
    },
  },

}
</script>
<style lang="scss">
.rotated {
  transform: rotate(-90deg);
  /* Equal to rotateZ(45deg) */
}

.modal-backdrop {
  opacity: 0.5 !important;
}
</style>
