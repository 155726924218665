<template>
  <div>

    <b-overlay
      :show="loading"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <customer-list-filters
        :date.sync="dateFilter"
      />
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="9"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  v-if="$can('customerFlow:update')"
                  :disabled="selectedCustomer.length === 0"
                  class="mr-1"
                  variant="primary"
                  @click="printMultipleUrl()"
                >
                  <span class="text-nowrap">Print selected</span>
                </b-button>
                <b-button
                  class="mr-1"
                  variant="primary"
                  @click="selectAllRows"
                >
                  <span class="text-nowrap">Select all</span>
                </b-button>
                <b-button
                  class="mr-1"
                  variant="primary"
                  @click="clearSelected"
                >
                  <span class="text-nowrap">Clear all</span>
                </b-button>
                <!-- <b-form-select
                  id="table-select-mode-select"
                  v-model="selectMode"
                  :options="modes"
                  class="mr-1"
                /> -->
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  v-if="$can('customerFlow:update')"
                  variant="primary"
                  class="mr-1"
                  @click="syncCustomer()"
                >
                  <span class="text-nowrap">Sync</span>
                </b-button>
                <b-button
                  v-if="$can('customerFlow:create')"
                  variant="primary"
                  class="mr-1"
                  @click="isShowAddCustomer = true"
                >
                  <span class="text-nowrap">Add</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <b-overlay
          :show="showTable"
          rounded="sm"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
        >
          <q-r-code
            v-if="qrData"
            :is-show-qr.sync="isShowQr"
            :code-customer="qrData"
            :sur-name="surName"
            :res-code="resCode"
            :guest-given="guestGiven"
          />
          <add-customer
            :is-show-add-customer.sync="isShowAddCustomer"
            @refetch-data="refreshTable"
          />
          <b-table
            ref="refCustomerTable"
            class="position-relative"
            :items="fetchPosts"
            responsive
            :fields="tableColumns"
            :select-mode="selectMode"
            primary-key="id"
            show-empty
            selectable
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            :sort-by.sync="sortBy"
            @row-selected="onRowSelected"
          >
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
            <!-- Column: arrivalDate -->
            <template #cell(surname)="data">
              <span class="text-capitalize">
                {{ data.item.surname }}
                <b-badge
                  v-if="!data.item.surname"
                  pill
                  variant="light-warning"
                  class="text-capitalize"
                >
                  None
                </b-badge>
              </span>
            </template>
            <template #cell(room)="data">
              <span class="text-capitalize">
                {{ data.item.room }}
              </span>
              <b-badge
                v-if="!data.item.room"
                pill
                variant="light-warning"
                class="text-capitalize"
              >
                None
              </b-badge>
            </template>
            <!-- Column: arrivalDate -->
            <template #cell(arrivalDate)="data">
              <span class="text-capitalize">
                {{ data.item.arrivalDate }}
              </span>
            </template>
            <!-- Column: arrivalDate -->
            <template #cell(departureDate)="data">
              <span class="text-capitalize">
                {{ data.item.departureDate }}
              </span>
            </template>
            <!-- Column: Actions -->
            <template #cell(action)="data">
              <b-link
                v-if="$can('customerFlow:update')"
                :to="{ name: 'detail-customer-flow', params: { id: data.item.id } }"
              >
                View
              </b-link>
              |
              <b-link
                v-if="$can('customerFlow:update')"
                @click="showQR(data.item)"
              >
                QR Code
              </b-link>
            </template>

          </b-table>
        </b-overlay>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
                entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalCustomer"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
    <b-col style="display: none;">
      <div
        ref="printMultiple"
        class="d-flex justify-content-center d-none"
      >
        <div>
          <div
            v-for="data in dataQr"
            :key="data.surName"
            class="qr"
          >
            <div
              class="text"
            >
              {{ checkHotel(data) }}
            </div>
            <div class="item">
              <qrcode-vue
                class="p-1"
                style="display: block"
                :value="data.url"
                :size="sizeQr"
                level="L"
              />
            </div>

          </div>
        </div>

      </div>
    </b-col>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BLink,
  BPagination, BOverlay, BBadge, BFormSelect,
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import customerFlow from '@/views/customerFlow/customerFlow'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import print from 'print-js'
import CustomerListFilters from '@/components/customerFlow/CustomerListFilters.vue'
import AddCustomer from './modal/AddCustomer.vue'
import QRCode from './modal/QRCode.vue'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
    BBadge,
    QrcodeVue,
    AddCustomer,
    BFormSelect,
    QRCode,
    CustomerListFilters,
  },
  data() {
    return {
      json_data: [
        {
          name: 'Tony Peña',
          city: 'New York',
          country: 'United States',
          birthdate: '1978-03-15',
          phone: {
            mobile: '1-541-754-3010',
            landline: '(541) 754-3010',
          },
        },
        {
          name: 'Thessaloniki',
          city: 'Athens',
          country: 'Greece',
          birthdate: '1987-11-23',
          phone: {
            mobile: '+1 855 275 5071',
            landline: '(2741) 2621-244',
          },
        },
      ],
    }
  },
  methods: {
    checkHotel(data) {
      console.log(data)
      const hotelKey = localStorage.getItem('hotelKey')
      if (hotelKey === '9') {
        return `${data.guestGiven} ${data.surName}`
      }
      return `${data.surName} - ${data.room}`
    },
    printModalBrowser() {
      // this.loading = true
      setTimeout(async () => {
        await print({
          maxWidth: 1000,
          style: '.item {margin-left: 6rem} .qr {margin-bottom: 1rem; display: flex; justify-content: center; align-items: center} .text {font-weight: bold; text-transform: uppercase; display: flex; text-align: center} ',
          printable: this.$refs.printMultiple,
          font_size: '30px',
          type: 'html',
        })

        // this.loading = false
      }, 10)
    },
    printMultipleUrl() {
      this.loading = true
      const idCustomers = this.selectedCustomer.map(x => x.id)
      const payload = {
        customerUrls: idCustomers,
      }
      this.$store.dispatch('customerFlow/genMultipleUrl', { object: payload })
        .then(response => {
          const { arrayUrl } = response.body
          this.dataQr = []
          arrayUrl.forEach(x => {
            const resCode = this.getParameterByName('resCode', x)
            const currentCustomer = this.selectedCustomer.find(i => i.resCode === resCode)
            this.dataQr.push({
              guestGiven: currentCustomer.guestGiven,
              surName: currentCustomer.surname,
              room: currentCustomer.room,
              url: x,
            })
          })
          this.printModalBrowser()
          this.loading = false
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
          this.loading = false
        })
    },
    getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, '\\$&')
      const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
      const results = regex.exec(url)
      if (!results) return null
      if (!results[2]) return ''
      return decodeURIComponent(results[2].replace(/\+/g, ' '))
    },
    selectAllRows() {
      this.$refs.refCustomerTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.refCustomerTable.clearSelected()
    },
    onRowSelected(items) {
      this.selectedCustomer = items
    },
    showQR(data) {
      this.loading = true
      this.$store.dispatch('customerFlow/fetchCustomerFlowQRLink', { id: parseInt(data.id) })
        .then(response => {
          this.qrData = response.body.url
          this.guestGiven = data.guestGiven
          this.surName = data.surname
          this.resCode = data.room
          this.isShowQr = true
          this.loading = false
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
          this.loading = false
        })
    },
    syncCustomer() {
      this.loading = true
      this.$store.dispatch('customerFlow/syncCustomers')
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sync customer success',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.loading = false
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
          this.loading = false
        })
    },
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    deleteCustomer(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this customer.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('customerFlow/deleteCustomerFlow', { id })
              .then(() => {
                this.refreshTable()
              }).catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.response.data.error,
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
  },

  setup() {
    const selectMode = ref('range')
    const qrData = ref(null)
    const surName = ref(null)
    const guestGiven = ref(null)
    const resCode = ref(null)
    const selectedCustomer = ref([])
    const isShowAddCustomer = ref(false)
    const loading = ref(false)
    const modes = ref(['multi', 'single', 'range'])
    const dataQr = ref([])
    const sizeQr = 270
    const {
      timeout,
      showTable,
      fetchPosts,
      tableColumns,
      perPage,
      currentPage,
      totalCustomer,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      refCustomerTable,
      refetchData,
      isShowQr,
      dateFilter,
      isSortDirDesc,
    } = customerFlow()
    return {
      guestGiven,
      surName,
      resCode,
      timeout,
      showTable,
      // Sidebar
      fetchPosts,
      tableColumns,
      perPage,
      currentPage,
      totalCustomer,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerTable,
      refetchData,
      isShowQr,
      // Filter
      avatarText,
      qrData,
      isShowAddCustomer,
      selectedCustomer,
      selectMode,
      modes,
      loading,
      dataQr,
      sizeQr,
      dateFilter,
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
