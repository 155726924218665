<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row class="d-flex">
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>Arrival date</label>
          <div class="d-flex">
            <b-form-datepicker
              class="mr-2"
              reset-button
              :reset-value="null"
              :value="date"
              :config="{ mode: 'range'}"
              @input="(val) => $emit('update:date', val)"
            />
          </div>

        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormDatepicker,
  },
  props: {
    date: {
      type: [String, null],
      default: null,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
